



























































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import DrawerComponent from '@common-src/mixins/drawer-component';
import { FacilityConfigAttributeEntityModel, FacilityConfigAttributeQueryModel } from '@common-src/entity-model/facility-config-attribute-entity';
import { FacilityAttributeDataType, FormControlSelectDeviceAttributeType } from '@common-src/model/enum';
import DeviceStatusDialog from '@common-src/pages3/dashboard/device/device-status-dialog.vue';
import FacilityConfigAttributeService from '@common-src/service/facility-config-attribute';

@Component({
    components: {
        'device-status-dialog': DeviceStatusDialog
    }
})
export default class FacilityAttributeDrawer extends Mixins(TableDialogFormComponent, DrawerComponent) {
    FacilityConfigAttributeEntityModel = FacilityConfigAttributeEntityModel;
    FacilityAttributeDataType = FacilityAttributeDataType;
    FormControlSelectDeviceAttributeType = FormControlSelectDeviceAttributeType;
    saveBtnDisabled: boolean = true;
    isFacilityType: boolean = null;
    facilityEntityId: string = null;
    facilityTypeId: string = null;

    init(service: any) {
        let tableColumns = null;
        if (this.isFacilityType) {
            tableColumns = FacilityConfigAttributeEntityModel.getTableColumns();
        } else {
            tableColumns = FacilityConfigAttributeEntityModel.getFacilityTabelColumns();
        }
        this.initTable({
            service: service,
            queryModel: new FacilityConfigAttributeQueryModel(this.facilityEntityId, this.isFacilityType),
            tableColumns
        });
    }

    drawAttributeOpen(facilityEntityId: string, isFacilityType: boolean, drawerTitle: string, service: any, facilityTypeId?: string) {
        this.listData = null;
        this.isFacilityType = isFacilityType;
        this.facilityTypeId = facilityTypeId;
        this.facilityEntityId = facilityEntityId;
        this.drawerTitle = drawerTitle;
        this.drawerOpen(facilityEntityId);
        if (this.service) {
            this.service = service;
            if ((this.queryModel as FacilityConfigAttributeQueryModel).entityId === facilityEntityId) {
                this.getList();
            } else {
                (this.queryModel as FacilityConfigAttributeQueryModel).entityId = facilityEntityId;
            }
        } else {
            this.init(service);
            this.getList();
        }
    }

    saveClick() {
        return FacilityConfigAttributeService.saveAttributes(this.listData as any).then(res => {
            this.showMessageSuccess('保存设施属性成功');
            this.saveBtnDisabled = true;
        });
    }
    detailClick(record: any) {
        // if ([FacilityAttributeDataType.DEVICE, FacilityAttributeDataType.ATTRIBUTE].indexOf(record.type) === -1) {
        //     return;
        // }
        // if (record.dataValue) {
        //     if (record.type === FacilityAttributeDataType.DEVICE) {
        //         (this.$refs.deviceStatusDialog as DeviceStatusDialog).dialogOpen(record.dataValue);
        //     } else if ([FacilityAttributeDataType.ATTRIBUTE, FacilityAttributeDataType.SERVER_ATTRIBUTE].indexOf(record.type) > -1) {
        //         const deviceId = getDeviceId(record.dataValue);
        //         const deviceAttributeKey = getDeviceIdAndAttributeKey(record.dataValue).attributeKey;
        //         (this.$refs.deviceStatusDialog as DeviceStatusDialog).dialogOpen(deviceId, deviceAttributeKey);
        //     }
        // } else {
        //     if (record.type === FacilityAttributeDataType.DEVICE) {
        //         this.showMessageInfo('请先选择设备');
        //     } else if ([FacilityAttributeDataType.ATTRIBUTE, FacilityAttributeDataType.SERVER_ATTRIBUTE].indexOf(record.type) > -1) {
        //         this.showMessageInfo('请先选择设备属性');
        //     }
        // }
    }

    getSaveBtnStatus(status) {
        this.saveBtnDisabled = status;
    }
}

